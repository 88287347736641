import React, { useState } from "react"
import AgTechAvailability from "../../components/africa/availability/agtech"
import EduTechAvailability from "../../components/africa/availability/edutech"
import HealthTechAvailability from "../../components/africa/availability/healthtech"
import Innovation from "../../components/africa/availability/other"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Location } from "@reach/router"

interface Props {
  location: any
}

interface TabProps {
  tabName: string
}

const TabSwitch = ({ tabName }: TabProps) => {
  switch (tabName) {
    case "HealthTech":
      return <HealthTechAvailability />
    case "EduTech":
      return <EduTechAvailability />
    case "AgTech":
      return <AgTechAvailability />
    case "Innovate":
      return <Innovation />
    default:
      return <HealthTechAvailability />
  }
}

const MainAvailabilityPage = ({ location }: Props) => {
  const [tab, setTab] = useState(
    location.state && location.state.initial
      ? location.state.initial
      : "HealthTech"
  )

  const classMap: any = {
    HealthTech: "healthtech",
    EduTech: "edutech",
    AgTech: "agtech",
    Innovate: "innovate",
  }

  return (
    <Layout>
      <SEO title="Availability" description="" lang="en" meta={[]} />

      <div className={`hero-wrapper small ${classMap[tab]}`}>
        <h1>{tab}</h1>
      </div>

      <section className="bg-darkened">
        <div className="container bg-lightened card pt-0 px-0">
          <nav className="tab-nav">
            <a
              onClick={() => setTab("HealthTech")}
              className={tab === "HealthTech" ? "active" : ""}
            >
              HealthTech
            </a>
            <a
              onClick={() => setTab("EduTech")}
              className={tab === "EduTech" ? "active" : ""}
            >
              EduTech
            </a>
            <a
              onClick={() => setTab("AgTech")}
              className={tab === "AgTech" ? "active" : ""}
            >
              AgTech
            </a>
            <a
              onClick={() => setTab("Innovate")}
              className={tab === "Innovate" ? "active" : ""}
            >
              Innovate
            </a>
          </nav>
          <div className="row">
            <TabSwitch tabName={tab} />
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default MainAvailabilityPage

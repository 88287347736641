import React from "react"
import AgroForm from "../../../components/africa/agform"

const agtech = require("../../../images/agtech.jpg")

const AgTechAvailability = () => (
  <section className="px-15">
    <div className="container">
      <div className="row d-flex align-items-end">
        <div className="col-lg-6 align-self-stretch d-flex flex-column justify-content-between">
          <h3 className="bold">Working with AgTech</h3>
          <p>
            From high-schoolers to doctoral students, we support students who
            are engaged in innovative research. Relevant research areas include
            topics related to computer science and engineering.
          </p>

          <img src={agtech} alt="" className="img-fluid" />
        </div>
        <div className="col-lg-6 mt-5">
          <AgroForm />
        </div>
      </div>
    </div>
  </section>
)

export default AgTechAvailability

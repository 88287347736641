import React from "react"
import OtherForm from "../../../components/africa/otherform"
const innovate = require("../../../images/thinking.jpg")

const Innovation = () => (
  <section className="px-15">
    <div className="container">
      <div className="row d-flex align-items-end">
        <div className="col-lg-6 align-self-stretch d-flex flex-column justify-content-between">
          <h3 className="bold">Innovating Together</h3>
          <p>
            From high-schoolers to doctoral students, we support students who
            are engaged in innovative research. Relevant research areas include
            topics related to computer science and engineering.
          </p>

          <img src={innovate} alt="" className="img-fluid" />
        </div>
        <div className="col-lg-6 mt-5">
          <OtherForm />
        </div>
      </div>
    </div>
  </section>
)

export default Innovation

import React from "react"
import HealthcareForm from "../../../components/africa/healthform"

const healthtech = require("../../../images/africahealthcare.jpg")

const HealthTechAvailability = () => (
  <section className="px-15">
    <div className="container">
      <div className="row d-flex align-items-end">
        <div className="col-lg-6 align-self-stretch d-flex flex-column justify-content-between">
          <h3 className="bold">Working with HealthTech</h3>
          <p>
            From high-schoolers to doctoral students, we support students who
            are engaged in innovative research. Relevant research areas include
            topics related to computer science and engineering.
          </p>

          <img src={healthtech} alt="" className="img-fluid" />
        </div>
        <div className="col-lg-6 mt-5">
          <HealthcareForm />
        </div>
      </div>
    </div>
  </section>
)

export default HealthTechAvailability
